import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import last from 'lodash/last';
import { Observable } from 'rxjs';

export type ReportFolderLevel = 'Root' | 'Connection' | 'Facility';

@Injectable({
  providedIn: 'root'
})
export class ReportFilesService {

  private document = inject(DOCUMENT);
  private http = inject(HttpClient);

  uploadReports(reportFiles: any, reportFolderLevel: ReportFolderLevel): Observable<any> {
    const uploadFormData = new FormData();
    uploadFormData.append('reportFiles', reportFiles);

    return this.http.put(`filemanager/uploadreports`, uploadFormData, {
      params: { reportFolderLevel: reportFolderLevel },
      reportProgress: true,
      observe: 'events'
    });
  }

  getReportFiles(reportFolderLevel: ReportFolderLevel): Observable<any> {
    const params = {
      reportFolderLevel
    };

    return this.http.get('filemanager/getreportnames', { params });
  }

  async downloadReportFile(filename: string, reportFolderLevel: ReportFolderLevel): Promise<any> {
    const params = {
      filename,
      reportFolderLevel
    };

    const fileData: any = await this.http.get('filemanager/downloadreport', {
      params,
      responseType: 'arraybuffer'
    }).toPromise();

    const downloadEl = this.document.createElement('a');
    downloadEl.setAttribute('download', filename);

    const fileBlob = new Blob([ fileData ], { type: 'text/frx' });
    const href = URL.createObjectURL(fileBlob);
    downloadEl.href = href;

    downloadEl.setAttribute('target', '_blank');

    downloadEl.click();

    URL.revokeObjectURL(href);
  }


  deleteReportFiles(fileNames: string[], reportFolderLevel: ReportFolderLevel): Observable<any> {
    const params = {
      fileNames,
      reportFolderLevel
    };

    return this.http.delete('filemanager/deletereports', { params });
  }
}

